<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('transaction_log.title') }}</h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        @column-select="onColumnSelect"
                        className="ml-2"
                    ></TableSettings>
                </div>
            </div>

            <ResizableTable
                class="main-data-table elevation-0 uveds-table"
                :rows="items"
                :columns="tableColumns"
                :sort-by="sortBy"
                :sort-dir="sortDir"

                @sort="sortItems"
                @columnMoved="onColumnsMoved"
                @columnResized="onColumnResized"
            >
                <template v-slot:uuid="{item}">
                    <RouterLink :to="{name: 'account_payment.edit', params: {uuid: item.uuid}}">
                        {{ item.uuid }}
                    </RouterLink>
                </template>
                <template v-slot:debit_account_id="{item}">
                    {{ item.debit_account.account_code }} - {{ item.debit_account.name }} [{{ item.debit_account.type.name }}]
                </template>
                <template v-slot:credit_account_id="{item}">
                    {{ item.credit_account.account_code }} - {{ item.credit_account.name }} [{{ item.credit_account.type.name }}]
                </template>
                <template v-slot:doc_id="{item}">
                    <RouterLink v-if="item.doc" :to="{name: item.doc.doc_type, params: {uuid: item.doc.uuid}}">
                        {{ $t(`${item.doc.doc_type}.self`) }} #{{ item.doc.id }}
                    </RouterLink>
                </template>
                <template v-slot:payment_direction_id="{item}">
                    <span :class="{
                        'green--text': item.payment_direction.slug === 'incoming',
                        'red--text': item.payment_direction.slug === 'outgoing',
                        'blue--text': item.payment_direction.slug === 'move'
                    }">{{ item.payment_direction.name }}</span>

                </template>
            </ResizableTable>

            <div class="main-pagination">
                <SelectInput
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    class="main-pagination__per-page"
                    @input="onPerPageChange($event)"
                    hide-label
                    background-color="white"
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" flat class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>
        <template v-slot:side>
            <Side/>
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import FilterView from "@/plugins/mixins/FilterView";
import Side from "@/components/Warehouse/Side.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import WithTransaction from "@/plugins/mixins/WithTransaction";
import SelectInput from "@/components/Form/SelectInput.vue";
import {mapGetters} from "vuex";

export default {
    name: "Sale",

    components: {
        SelectInput,
        Side,
        PageLayout,
    },

    mixins: [
        NavMixin,
        FilterView,
        ResourceView,
        WithTransaction
    ],

    data() {
        return {
            entityName: 'transaction',
            invalid: false,
            items: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            loading: false
        }
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getItems();
        },

        getItems() {
            this.loading = true;
            this.fetchTransactions().then((response) => {
                this.items = response.data;
                this.pageCount = response.last_page;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss">
</style>
